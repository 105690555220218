import React from 'react'

const isBrowser = () => typeof window !== 'undefined' && window

const sidebar = {
  open: (height = 1000) => ({
    clipPath: `circle(${height * 2 + 200}px at calc(100% - 35px) 40px)`,
    transition: {
      type: 'spring',
      stiffness: 20,
      restDelta: 2
    }
  }),
  closed: {
    clipPath: 'circle(0px at calc(100% - 32px) 48px)',
    transition: {
      delay: 0.5,
      type: 'spring',
      stiffness: 400,
      damping: 40
    }
  }
}

const sidebarWrapper = {
  open: {
    display: 'block'
  },
  closed: {
    display: 'none',
    transition: {
      delay: 1.5
    }
  }
}

const defaultState = {
  isSidebarOpen: false,
  toggleSidebar: () => {}
}

const ThemeContext = React.createContext(defaultState)

class ThemeProvider extends React.Component {
  state = {
    isSidebarOpen: false,
    locale: ''
  }

  toggleSidebar = () => {
    let isSidebarOpen = !this.state.isSidebarOpen
    localStorage.setItem('Theme.isSidebarOpen', JSON.stringify(isSidebarOpen))

    this.toggleBodyScroll(isSidebarOpen)

    this.setState({ isSidebarOpen })
  }

  closeSidebar = () => {
    localStorage.setItem('Theme.isSidebarOpen', JSON.stringify(false))
    this.toggleBodyScroll(false)
    this.setState({ isSidebarOpen: false })
  }

  openSidebar = () => {
    localStorage.setItem('Theme.isSidebarOpen', JSON.stringify(true))
    this.toggleBodyScroll(true)
    this.setState({ isSidebarOpen: true })
  }

  toggleBodyScroll = enable => {
    if (enable) {
      document.body.style.overflow = 'hidden'
      document.documentElement.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = ''
      document.documentElement.style.overflow = ''
      document.body.style.overflowX = 'hidden'
      document.documentElement.style.overflowX = 'hidden'
    }
  }

  componentDidMount() {
    const lsisSidebarOpen = JSON.parse(
      localStorage.getItem('Theme.isSidebarOpen')
    )

    this.toggleBodyScroll(lsisSidebarOpen)

    if (lsisSidebarOpen) {
      this.setState({ isSidebarOpen: lsisSidebarOpen })
    }
  }

  langSelector = () => {
    const hasWindow = typeof window !== 'undefined' ? true : false
    const location = (hasWindow && window.location) || false
    const lang = hasWindow
      ? location.pathname.includes('/zh/')
        ? 'zh'
        : 'en'
      : 'en'
    this.setState({ locale: lang })
  }

  render() {
    const { children } = this.props
    const { isSidebarOpen } = this.state
    const { locale } = this.state
    return (
      <ThemeContext.Provider
        value={{
          isSidebarOpen,
          toggleSidebar: this.toggleSidebar,
          openSidebar: this.openSidebar,
          closeSidebar: this.closeSidebar,
          locale,
          setLocale: this.langSelector
        }}
      >
        {children}
      </ThemeContext.Provider>
    )
  }
}
export default ThemeContext

export { ThemeProvider, isBrowser, sidebar, sidebarWrapper }
